<template>
    <div class="wrap">
        <sNav :list="navList" @setNav="setNav"></sNav>
        <flagList :list="list"></flagList>
        <div class="page-box" v-show="total">
            <el-pagination :pager-count="5" :page-size="20" @current-change="changePage" background layout="prev, pager, next" :total="total"></el-pagination>
        </div>
    </div>
</template>

<script>
    import sNav from "@components/common/nav";
    import flagList from "@components/common/flag-list";
    import {
        mapGetters,
        mapMutations
    } from "vuex";
    export default {
        components: {
            sNav,
            flagList,
        },
        data() {
            return {
                navList: [{
                        id: 2,
                        title: "想去", // 一次性
                    },
                    {
                        id: 1,
                        title: "小目标", // 常规
                    },
                ],
                list: [],
                navId: 2,
                total: 0,
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
            this.setMeta("小目标", 1);
        },
        mounted() {
            // 在组件被挂载之后调用。
            this.getData(1);
        },
        methods: {
            // 切换栏目
            setNav(m) {
                this.navId = m.id;
                this.getData(1);
            },
            changePage(s) {
                this.getData(s);
            },
            // 获取列表
            async getData(page) {
                let s = this.navId == 2 ? 0 : 1;
                let param = {
                    nav: 1, // 1全部 2同城 3关注
                    status: 0, // 0所有 1进行中
                    type: this.navId, // 0所有1常规flag|2一次性flag
                };
                param.page = page || 1;
                let {
                    list,
                    total
                } = await this.$y_list("api/Flag/flagsul", [], param);
                this.list = list;
                if (total != -1) {
                    this.total = total;
                }
            },
        },
        computed: {
            // 计算属性
            ...mapGetters(["s_getUser"]),
        },
        watch: {
            // 监听
            s_getUser(n) {
                this.getData(1);
            },
        },
    };
</script>

<style lang="less" scoped></style>