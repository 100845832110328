<template>
  <div>
    <el-empty v-if="list.length == 0" description="空空如也~"></el-empty>
    <el-row v-else>
      <el-col class="flag-list" :key="index" v-for="(m, index) in list">
        <router-link :to="{ path: '/flagdes', query: { id: m.id } }">
          <el-skeleton :loading="loading" animated :count="1">
            <template slot="template">
              <el-skeleton-item variant="image" class="img" />
              <div class="content">
                <el-skeleton-item class="" variant="h3" style="width: 70%;" />
                <el-skeleton-item variant="text" style="width: 50%;" />
              </div>
              <div class="bottom flex-bt">
                <el-skeleton-item variant="text" style="width: 30%;" />
                <el-skeleton-item variant="text" style="width: 30%;" />
              </div>
            </template>
            <template>
              <el-image class="img " :class="{ 'video-img': m.video }" :src="rImg(m.images[0])" fit="cover">
                <div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div>
              </el-image>
              <div class="content">
                <div class="title ellipsis-2">{{ m.content }}</div>
                <div class="tag-box" v-if="m.flag">
                  <div class="tag">
                    <span class="ellipsis-1 tag-name">#{{ m.flag.content }}</span>
                    <span v-if="m.flag.price">· ￥{{ m.flag.price }}挑战金</span>
                  </div>
                </div>
                <p class="area" v-if="m.address && m.flag && m.flag.type == 1">
                  <i class="iconfont icon-zhiyuandidian6"></i>
                  {{ m.address }}
                </p>
              </div>
              <div class="bottom flex-bt">
                <div class="user" v-if="m.user">
                  <img :src="rImg(m.user.head_100)" :onerror="txImg" class="tx" alt="" />
                  <span class="name ellipsis-1">{{ m.user.name }}</span>
                </div>
                <div class=" flex" v-if="m.flag">
                  <i class="iconfont icon-rili"></i>
                  <span class="see">{{ m.day }}/{{ m.flag.days }}天</span>
                </div>
              </div>
            </template>
          </el-skeleton>
        </router-link>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "flagList",
  components: {},
  props: ["list"],
  data() {
    return {
      loading: false,
    };
  },
  created() {
    // 在组件实例处理完所有与状态相关的选项后调用。
  },
  mounted() {
    // 在组件被挂载之后调用。
  },
  methods: {},
  computed: {
    // 计算属性
  },
  watch: {
    // 监听
  },
};
</script>

<style lang="less" scoped>
@import "@assets/less/flag.less";

.tag-box {
  display: flex;
  .tag {
    display: flex;
    align-items: center;

    .tag-name {
      max-width: 55%;
      margin-right: 5px;
    }
  }
}
</style>
